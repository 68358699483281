var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-select",
        {
          attrs: {
            value: _vm.value,
            loading: _vm.loading,
            disabled: _vm.disabled,
            "dropdown-match-select-width": false,
            "allow-clear": "",
            "show-search": "",
            placeholder: _vm.$t("common.select-text", {
              text: _vm.$t("lbl_transaction_type")
            }),
            "option-label-prop": "label"
          },
          on: { change: _vm.onChange },
          scopedSlots: _vm._u(
            [
              _vm.hasCreate
                ? {
                    key: "dropdownRender",
                    fn: function(menu) {
                      return [
                        _c("VNodes", { attrs: { items: menu } }),
                        _c("a-divider", { staticClass: "my-1" }),
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "plus",
                              block: "",
                              loading: _vm.loadingAdd
                            },
                            on: {
                              mousedown: function(ev) {
                                return ev.preventDefault()
                              },
                              click: _vm.handleAdd
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common.add-text", {
                                    text: _vm.$t("lbl_new")
                                  })
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                : null
            ],
            null,
            true
          )
        },
        _vm._l(_vm.options, function(option) {
          return _c(
            "a-select-option",
            { key: option.key, attrs: { label: option.label } },
            [
              _c("p", { staticClass: "text-title mb-0" }, [
                _vm._v(_vm._s(option.label))
              ]),
              option.meta
                ? _c("small", { staticClass: "text-subtitle" }, [
                    _vm._v(_vm._s(option.meta))
                  ])
                : _vm._e()
            ]
          )
        }),
        1
      ),
      _c("AmortizationTransactionTypeModals", {
        on: { finish: _vm.handleFinish },
        model: {
          value: _vm.modalState.visible,
          callback: function($$v) {
            _vm.$set(_vm.modalState, "visible", $$v)
          },
          expression: "modalState.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }