






import Vue from "vue";

export default Vue.extend({
  name: "VNodes",
  props: ["items"],
  components: {
    OptionNode: {
      functional: true,
      render: (h, ctx) => ctx.props.items,
    },
  },
});
