







































import MNotification from "@/mixins/MNotification.vue";
import { amortizationService } from "@/services/amortization.service";
import { FormUtils } from "@/utils/FormUtils";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  name: "AmortizationTransactionTypeModals",
})
export default class AmortizationTransactionTypeModals extends Mixins(
  MNotification
) {
  @Prop({ required: false, type: Boolean, default: false })
  readonly value!: boolean;

  @Ref()
  readonly formRef!: FormModel;

  formState = {
    name: "",
    description: "",
  };

  validationSchema = {
    name: FormUtils.mandatory(),
  };

  loading = false;

  @Watch("value")
  onChangeValue(val: boolean): void {
    if (!val) this.formRef.resetFields();
  }

  handleCancel(): void {
    this.$emit("input", false);
    this.$emit("close");
  }

  handleSubmit(): void {
    this.formRef.validate(valid => {
      if (!valid) return;

      this.handleCreate(this.formState);
    });
  }

  handleCreate(state: { name: string; description: string | undefined }): void {
    this.loading = true;
    amortizationService
      .createTransactionTypes({
        name: state.name,
        description: state.description || null,
      })
      .then(response => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.name,
        });
        this.$emit("finish", { payload: response });
        this.handleCancel();

        this.formRef.resetFields();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
