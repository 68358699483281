var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("common.create-text", {
          text: _vm.$t("lbl_amortization_transaction_type")
        }),
        visible: _vm.value,
        "cancel-text": _vm.$t("lbl_close"),
        "ok-text": _vm.$t("lbl_create"),
        "ok-button-props": {
          props: {
            loading: _vm.loading
          }
        }
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleSubmit }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: { model: _vm.formState, rules: _vm.validationSchema }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_name"), prop: "name" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_transaction_type")
                  })
                },
                model: {
                  value: _vm.formState.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "name", $$v)
                  },
                  expression: "formState.name"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_description"), prop: "description" }
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_description")
                  })
                },
                model: {
                  value: _vm.formState.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "description", $$v)
                  },
                  expression: "formState.description"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }